.iframe_overlay {
  background-color: white;
  opacity: 0;
  transition: all 0.3s;
  .label {
    position: absolute;
    background: red;
    color: white;
    font-size: 20px;
    height: 20px;
    width: 100px;
    text-align: center;
  }
}

.iframe_overlay.hover {
  opacity: 0.4;
  cursor: pointer;
}
