@import '../../../scss/variables.scss';
@import './popup.mixin.scss';

.Popup-content-infobox {
  height: 100%;
  display: flex;
  justify-content: center;

  .Popup-content-wp {
    background-color: $black-color;
    height: fit-content;

    .infobox-img-wp {
      img {
        width: 100%;
        height: 100%;
      }
    }

    .infobox-text-wp {
      padding: 20px 25px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-top-width: 0;

      @media (max-width: 767px) {
        padding: 10px 10px;
      }

      .infobox-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;

        h2 {
          font-size: 1.875rem;
          line-height: 1.3;
          color: $white-color;
          overflow-wrap: break-word;

          @media (max-width: 767px) {
            font-size: 1.4rem;
          }
        }

        .readmore-btn {
          align-self: flex-start;
        }
      }

      .infobox-description {
        margin-top: 0.5rem;

        p {
          text-align: justify;
          color: $white-color;
          font-size: 1.125rem;
          line-height: 1.4;

          #truncate-text {
            display: inline-block;
            max-width: calc(100% - 60px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
          }

          #collapse-text,
          #expand-text {
            display: inline-block;
            width: 60px;
            color: $blue-color;
            vertical-align: middle;
            cursor: pointer;
          }

          @media (max-width: 767px) {
            font-size: 1rem;
          }
        }

        p:not(:last-child) {
          margin-bottom: 10px;
        }

        /* Scrollbar styles */
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          background: $light-grey;
          border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background: $white-color;
          border-radius: 8px;
        }
      }
    }

    .portrait-maxheight {
      max-height: 85vh;
    }

    .landscape-maxheight {
      max-height: 67.5vh;
    }
  }
}
