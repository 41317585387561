.threed-map-backdrop {
  position: absolute;
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Menu-left {
  .threed-map-backdrop {
    left: -40px;
  }
}

.Menu-bottom {
  .threed-map-backdrop {
    bottom: -40px;
  }
}
.map_iframe {
  iframe {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 90%;
    height: 70vh;
  }
}
