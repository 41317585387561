@import '../../../../scss/variables.scss';

.Popup-content-video {
  .Popup-content-wp {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // height: 100%;

    .embeded-content {
      position: absolute;
      left: 0;
      right: 0;
      top: unset;
      width: 100vw;
      height: 100vh;
      display: flex;
      place-items: center;
      place-content: center;
    }

    iframe {
      flex: 1;
      width: 100%;
      height: 100%;
    }

    @media (min-width: $size-min-md) and (max-width: calc(#{$size-min-xl} - 1px)) {
      top: 10vh;
      bottom: 5vh;
      left: 0;
      right: 0;

      .embeded-content {
        padding: 0;
      }

      iframe {
        width: 100vw;
        height: 70vh;
      }
    }

    @media (min-width: $size-min-xl) {
      top: 10vh;
      bottom: 5vh;
      left: 0;
      right: 0;

      .embeded-content {
        padding: 0 15vw;
      }

      iframe {
        width: 80vw;
        height: 70vh;
        border-radius: 15px;
      }
    }
  }
}
