.close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 34px;
  border: none;
  border-radius: 20px;
  background-color: #d8d8d8;
  cursor: pointer;

  p {
    font-size: 18px;
    font-family: var(--secondary-font);
    font-weight: 300;
    color: #000;
  }
}

.close-icon {
  width: 16px;
  height: 16px;
}
