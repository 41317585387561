:root {
  --primary-color: rgb(9, 112, 197);
  --size-min-sm: 576px;
  --size-min-md: 768px;
  --size-min-lg: 992px;
  --size-min-xl: 1200px;
  --primary-font: 'Source Sans Pro', sans-serif;
  --secondary-font: 'Roboto', sans-serif;
  --hotspot-font: 'Roboto Condensed', sans-serif;
  --condensed-font: 'Roboto Condensed', sans-serif;
  --montserrat-font: 'Montserrat', sans-serif;
  --advent-pro-font: 'Advent Pro', sans-serif;
  --tour-brandColor: #f73d3c;
}

.font-source-sans {
  font-family: var(--primary-font);
}

.font-roboto {
  font-family: var(--secondary-font);
}

.font-roboto-condensed {
  font-family: var(--hotspot-font);
}

html,
body {
  font-size: 16px;
  height: 100vh;
  height: var(--app-height);
}

body {
  margin: 0;
  font-family: var(--secondary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
  /* font-family: var(--primary-font); */
  font-family: var(--secondary-font);
  user-select: none;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
