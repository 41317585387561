.Popup-content-pdf {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
