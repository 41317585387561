.hotspot_icon_custom,
.hotspot_icon {
  width: 20px;
  height: 20px;
  @extend .center-div;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid white;
  position: absolute;
  z-index: 2;

  &-hide {
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }

  .hotspot_instruction-left {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -55px;
    left: -100px;
    color: black;
    font-size: 20px;
    width: 230px;
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    font-weight: bold;
  }

  .hotspot_instruction-right {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 10px 20px;
    position: absolute;
    font-weight: bold;
    top: -55px;
    left: -105px;
    color: black;
    font-size: 20px;
    width: 270px;
    white-space: nowrap;
  }
}

.hotspot_icon_custom {
  background-color: transparent;
  border: none;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 2.5s infinite;
  -moz-animation: flickerAnimation 2.5s infinite;
  -o-animation: flickerAnimation 2.5s infinite;
  animation: flickerAnimation 2.5s infinite;
}
