.loading_model_spinner {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  .loading-logo {
    width: 100%;
    text-align: center;
  }
}

.change_mode_button {
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
  button {
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    text-transform: uppercase;
    color: black;
    width: 150px;
  }
}

/* This keeps child nodes hidden while the element loads */
// :not(:defined) > * {
//   display: none;
// }

model-viewer {
  background-color: #eee;
  overflow-x: hidden;
  display: block;
  height: 100%;
  width: 100%;
}

#ar-button {
  background-image: url(./ic_view_in_ar_new_googblue_48dp.png);
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 12px 50%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  bottom: 132px;
  padding: 0px 16px 0px 40px;
  font-family: Roboto Regular, Helvetica Neue, sans-serif;
  font-size: 14px;
  color: #4285f4;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  border: 1px solid #dadce0;
}

#ar-button:active {
  background-color: #e8eaed;
}

#ar-button:focus {
  outline: none;
}

#ar-button:focus-visible {
  outline: 1px solid #4285f4;
}

@keyframes circle {
  from {
    transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg);
  }
}

@keyframes elongate {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(-100px);
  }
}

model-viewer > #ar-prompt {
  position: absolute;
  left: 50%;
  bottom: 175px;
  animation: elongate 2s infinite ease-in-out alternate;
  display: none;
}

model-viewer[ar-status='session-started'] > #ar-prompt {
  display: block;
}

model-viewer > #ar-prompt > div {
  animation: circle 4s linear infinite;
}

model-viewer > #ar-failure {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 175px;
  display: none;
}

model-viewer[ar-tracking='not-tracking'] > #ar-failure {
  display: block;
}

.slider {
  width: 100%;
  text-align: center;
  overflow: hidden;
  position: absolute;
  bottom: 16px;
}

.slides {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.slide {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  margin-right: 10px;
  border-radius: 10px;
  border: none;
  display: flex;
}

.slide.selected {
  border: 2px solid #4285f4;
}

.slide:focus {
  outline: none;
}

.slide:focus-visible {
  outline: 1px solid #4285f4;
}
