.aacorp-modal {
  &__wrapper {
    position: fixed;
    height: 100vh;
    width: 100vw;
    inset: 0;
    pointer-events: none;
    z-index: 10;
  }

  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__box {
    margin: 0 auto;
    width: 60%;
    pointer-events: all;
  }

  &__header {
    position: relative;
    padding: 20px;
    width: 100%;
    height: 120px;
    background: linear-gradient(to right, #f67d1c, #e2452d);
  }

  &__arrow {
    font-size: 40px;
    color: black;
    cursor: pointer;
    left: 49%;
  }

  &__arrow-up {
    position: absolute;
    top: 55px;
    rotate: 90deg;
    opacity: 0.7;
    transform: translateY(-3px);
  }

  &__arrow-down {
    position: absolute;
    bottom: -18px;
    rotate: 270deg;
    opacity: 0.7;
  }

  &__img {
    img {
      width: 135px;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    background: none;
    color: #333;
  }

  &__close-btn {
    display: flex;
    height: 40px;
    padding: 0px 10px;
    line-height: 1.1;
    border-radius: 50%;
    font-size: 30px;
    border: 1px solid gray;
    cursor: pointer;
    opacity: 0.85;
    &:hover {
      opacity: 1;
    }
  }

  &__body {
    width: 100%;
    height: 50vh;
    background-color: white;
    position: relative;
  }

  &__body-wrapper {
    position: absolute;
    top: -55px;
    left: 0;
    height: 100%;
  }

  &__body-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
  }

  &__title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transform: translateY(30px);
    h3 {
      font-size: 1.5vw;
      line-height: 25px;
      color: #f7801c;
    }
  }

  &__content {
    position: relative;
    margin-top: 15px;
    margin-right: 20px;
    height: calc(100% - 120px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 35px;

    h5 {
      font-size: 18px;
      line-height: 1.8;
      font-weight: bold;
      margin-bottom: 10px;
    }

    h6 {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.8;
      font-style: italic;
    }

    p {
      font-size: 16px;
      line-height: 1.4;
      font-weight: 300;
      text-align: justify;
      margin-bottom: 20px;
    }

    @media (min-width: 1440px) {
      // margin-top: 55px;
    }
  }

  &__desc {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    div {
      margin-top: 5px;
      font-size: 16px;
      line-height: 1.3;
      .mb-3 {
        margin-bottom: 15px;
      }
    }

    @media (max-width: 480px) {
      // grid-template-columns: 0.5fr 3fr;
      gap: 20px;
      h5 {
        font-size: 16px;
        line-height: 1.3;
      }
      div {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 767px) {
    &__box {
      width: 100vw;
      height: 100vh;
    }

    &__body {
      height: 100%;
    }

    &__body-wrapper {
      top: -80px;
    }

    &__body-up {
      flex-direction: column;
    }

    &__title {
      margin-top: 10px;
      padding: 0;
      transform: translateY(0);
      h3 {
        margin-top: 0;
        text-align: center;
        font-size: 16px;
      }
    }

    &__content {
      margin-right: 0;
      height: calc(100% - 310px);
    }
  }
}
