.menuV2-mobile {
  &--container {
    position: fixed;
    height: auto;
    width: 100vw;
    bottom: 0;

    .Menu {
      width: 300px;
      height: auto;
      max-height: 100vh;
      background-color: $white-color;
      box-sizing: border-box;
      padding: 10px;
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }

      .Menu-title {
        margin: 0;
        text-align: center;
        font-size: 1.5rem;
      }

      .Menu-items {
        margin-top: 15px;

        .Menu-item {
          margin-top: 5px;
          padding: 5px 0;
          border: 1px solid gray;
          color: black;
          cursor: pointer;
          text-align: center;

          &.Menu-item-active {
            color: var(--primary-color);
            cursor: default;
            font-weight: bold;
          }

          &:hover {
            color: $white-color;
            background-color: var(--primary-color);
          }
        }
      }
    }
  }
  &--top {
    width: 100vw;
    height: 14px;
    background-color: $menu_close_bg_color;
    border-radius: 10px 10px 0px 0px;

    @extend .center-div;

    &_line {
      width: 30px;
      height: 4px;
      background-color: $menu_line_color;
    }
  }
  &--bottom {
    width: 100vw;
    height: 20px;
    background-color: $menu_close_bg_color;

    @extend .center-div;

    &_line {
      width: 130px;
      height: 4px;
      background-color: $white-color;
    }
  }
  &--header {
    height: 56px;
    width: 100vw;
    display: flex;
    transition: top 1s ease-in-out, bottom 1s ease-in-out;
    background-color: $menu_close_bg_color;
    border-bottom: 1px solid $menu_line_color;
    &_icon {
      width: 52px;
      display: flex;
      justify-content: center;
      margin-top: 3px;
      img {
        width: 19px;
        height: 32px;
      }
    }
    &_titles {
      padding-bottom: 10px;
      &_title {
        color: $light_green;
        font-size: 15px;
        text-transform: uppercase;
      }
      &_label {
        color: $white-color;
        font-size: 9px;
      }
    }
  }
  &--icons {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 30px;
    margin-bottom: 10px;
    .vz-logo {
      margin-left: 14px;
      opacity: 0.5;

      img {
        width: 50px;
        height: 15px;
      }
    }

    .icons {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      margin-right: 5px;
      transition: transform 0.3s ease;

      &-school__layout {
        position: fixed;
        top: 10px;
        @media (min-width: 480px) {
          position: absolute;
        }
      }

      @include tablet {
        margin-right: 11px;
      }

      &_icon {
        width: 30px;
        height: 30px;
        background-color: $menu_close_bg_color;
        border-radius: 10px;
        margin: 5px;
        @extend .center-div;

        &-school_layout-gyro {
          background-color: transparent;
          width: unset;
          height: unset;
          margin: unset;
          margin-bottom: 5px;
          z-index: 2;
        }
      }

      .tour-icons {
        margin: 5px;
      }

      .icon-share {
        width: 30px;
        height: 30px;
        background-color: $white-color;
        border-radius: 10px;
        // margin: 0 0 10px 10px;
        pointer-events: all;
        cursor: pointer;
        @extend .center-div;
        @extend .menu-hover-opacity;

        @media (min-width: 481px) {
          width: 32px;
          height: 32px;
          margin: 0;
          fill: $light_green;
        }

        @include desktop {
          width: 40px;
          height: 40px;
          margin: 0;
        }

        svg {
          @media (min-width: 481px) {
            width: 24px;
            height: 24px;
          }

          @include desktop {
            width: 27px;
            height: 27px;
          }
        }
      }
    }

    .icons.translateY {
      transform: translateY(-120px);
    }

    .icon-floor {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.menuV2-desktop {
  &--container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 10px 10px 0;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 2;
    pointer-events: none;

    &.aacorp {
      top: unset;
      bottom: 0 !important;
      align-items: flex-end;
      padding: 0 10px 10px;
    }

    @media (min-width: 1441px) {
      padding: 15px 15px 0;
    }

    &_left {
      width: 450px;
      display: flex;
      align-items: flex-start;
      pointer-events: all;

      @media (min-width: 481px) and (max-width: 580px) {
        width: 360px;
      }

      .header {
        height: 60px;
        width: 100%;
        display: flex;
        transition: top 1s ease-in-out, bottom 1s ease-in-out;

        background-color: rgba(0, 0, 0, 0.6);
        border-bottom: 1px solid $menu_line_color;
        border-radius: 10px;
        margin-bottom: 5px;

        &:hover {
          background-color: $menu_close_bg_color;
        }

        &_icon {
          width: 52px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 19px;
            height: 32px;
          }
        }
        &_titles {
          display: flex;
          flex-direction: column;
          justify-content: center;
          &_title {
            color: $light_green;
            font-size: 15px;
            text-transform: uppercase;
          }
          &_label {
            color: $white-color;
            font-size: 9px;
            font-weight: $font-weight-light;
          }
        }
      }

      .pano-menu {
        &_open {
          display: block;
        }
        &_close {
          display: none;
        }
      }

      .icons_left {
        display: flex;
        align-items: center;

        @media (min-width: 481px) and (max-width: 768px) {
          flex-direction: column;
        }
      }

      .icon {
        width: 32px;
        height: 32px;
        background-color: $menu_close_bg_color;
        border-radius: 10px;
        cursor: pointer;
        @extend .center-div;
        margin-left: 5px;

        @media (min-width: 481px) and (max-width: 768px) {
          margin-bottom: 7px;
        }

        @include desktop {
          width: 40px;
          height: 40px;
          margin-left: 7px;
        }

        svg {
          width: 22px;
          height: 22px;
          @include desktop {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    &_left.container-width {
      width: calc(15vw - 10px);
      max-width: 190px;
      min-width: 190px;
      transition: all 0.3s ease;
    }

    &_left.container-width-closed {
      width: 0;
      max-width: 0;
      min-width: 0;
    }

    &_right {
      display: flex;
      justify-content: space-between;
      pointer-events: none;

      @media (min-width: 481px) and (max-width: 768px) {
        flex-direction: column;
      }

      @media (min-width: 769px) {
        flex: 1;
      }

      .icons_right {
        display: flex;
        align-items: center;
        pointer-events: all;
        @media (min-width: 481px) and (max-width: 768px) {
          flex-direction: column;
        }

        .tour-icons {
          margin-left: 7px;

          @media (max-width: 1023px) {
            margin-left: 0;
          }
        }

        .icon-share {
          width: 30px;
          height: 30px;
          background-color: $white-color;
          border-radius: 10px;
          margin: 0 0 10px 10px;
          pointer-events: all;
          cursor: pointer;
          @extend .center-div;
          @extend .menu-hover-opacity;

          @media (min-width: 481px) {
            width: 32px;
            height: 32px;
            margin: 0;
            fill: $light_green;
          }

          @include desktop {
            width: 40px;
            height: 40px;
            margin: 0;
          }

          svg {
            @media (min-width: 481px) {
              width: 24px;
              height: 24px;
            }

            @include desktop {
              width: 27px;
              height: 27px;
            }
          }
        }
      }

      .icon {
        width: 32px;
        height: 32px;
        background-color: $menu_close_bg_color;
        border-radius: 10px;
        cursor: pointer;
        @extend .center-div;
        margin-left: 5px;
        @extend .menu-hover-opacity;

        @media (min-width: 481px) and (max-width: 768px) {
          margin-left: 0;
          margin-bottom: 7px;
        }

        @include desktop {
          width: 40px;
          height: 40px;
          margin-left: 7px;
        }

        svg {
          width: 22px;
          height: 22px;
          @include desktop {
            width: 27px;
            height: 27px;
          }
        }
      }

      .icons_left {
        margin-left: 5px;

        @media (min-width: 1441px) {
          margin-left: 7px;
        }

        .icon:first-child {
          margin-left: 0;
        }
      }

      .icons_left.margin-left__0 {
        margin-left: 0;
      }

      .icon_audio {
        svg {
          @include desktop {
            width: 40px;
            height: 40px;
          }
        }
      }

      .school-layout {
        flex-direction: column;
        gap: 5px;
        .icon {
          background-color: transparent;
          svg {
            filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
          }
        }
      }

      .icon-share-school__layout {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        margin-left: 5px;
        cursor: pointer;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
        svg {
          height: 20px;
          width: 20px;
          filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
        }
      }
    }
  }
}

.tour-infor {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  z-index: 1;
  pointer-events: none;

  @include desktop {
    margin-right: 5px;
  }

  @media (min-width: 481px) {
    align-items: flex-end;
    top: auto;
    bottom: 10px;
    margin-right: 0;
  }

  @media (min-width: 1441px) {
    right: 15px;
    bottom: 15px;
  }

  @media (max-width: 480px) {
    align-items: flex-start;
    right: 10px;
  }

  &_navMenuOpen {
    display: none;
    @media (min-width: 481px) {
      display: flex;
    }
  }

  &_school-layout {
    top: 10px;
    left: 10px;
    bottom: unset;

    @media (min-width: 1441px) {
      top: 15px;
      left: 15px;
    }

    @media (min-width: 1441px) {
      top: 20px;
      left: 20px;
    }
  }

  .tour-logo {
    max-width: 100px;

    &__aacorp {
      margin-top: 10px;
      margin-right: 10px;
      position: fixed;
      top: 0;
      right: 0;
    }

    @media (min-width: 481px) and (max-width: 599px) {
      margin-right: 7px;
      min-height: 40px;
      max-height: 87px;
      max-width: 150px;
    }

    @include tablet {
      display: flex;
      justify-content: center;
      margin-right: 7px;
      min-height: 40px;
      max-height: 87px;
      max-width: 150px;
    }

    @include desktop {
      margin-right: 15px;
      min-height: 60px;
      max-height: 135px;
      max-width: 250px;
    }

    img {
      max-width: 100%;
      min-height: 30px;
      max-height: 67.5px;

      @media (min-width: 481px) and (max-width: 599px) {
        min-height: 40px;
        max-height: 87px;
      }

      @include tablet {
        min-height: 40px;
        max-height: 87px;
      }

      @include desktop {
        min-height: 60px;
        max-height: 135px;
      }
    }
  }

  .tour-icons {
    display: flex;
    flex-direction: column;
  }

  .icon-share {
    width: 30px;
    height: 30px;
    background-color: $white-color;
    border-radius: 10px;
    margin: 0 0 10px 10px;
    pointer-events: all;
    cursor: pointer;
    @extend .center-div;
    @extend .menu-hover-opacity;

    @media (min-width: 481px) {
      width: 32px;
      height: 32px;
      margin: 0;
      fill: $light_green;
    }

    @include desktop {
      width: 40px;
      height: 40px;
      margin: 0;
    }

    svg {
      @media (min-width: 481px) {
        width: 24px;
        height: 24px;
      }

      @include desktop {
        width: 27px;
        height: 27px;
      }
    }
  }

  .tour-guild__aacorp {
    position: fixed;
    bottom: 8vh;
    right: 0;
    margin-right: 5px;
    img {
      height: 160px;
    }
  }
}

.school-layout {
  &_scene-title {
    font-size: 22px;
    position: fixed;
    left: 48px;
    bottom: -4px;
    color: white;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
    @media (min-width: 481px) {
      bottom: 0;
      left: 50px;
    }
  }
}

.navMenuOpen {
  display: none;
}

.icon_language {
  width: 22px;
  height: 40px;
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;

  @extend .center-div;
  margin-left: 15px;
  @include desktop {
    width: 32px;
    height: 60px;
  }
}

.icons_language-icon {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  overflow: hidden;
  @extend .center-div;
  background-color: #fff;
  cursor: pointer;
  h1 {
    font-size: 16px;
    font-weight: 400;
  }

  @media (max-width: 480px) {
    margin: 5px;
  }
}

.language-icon {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 7px;
  @extend .center-div;
  background-color: #fff;
  cursor: pointer;

  @include desktop {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
  }

  h1 {
    font-size: 20px;
    font-weight: 400;

    @include desktop {
      font-size: 24px;
    }
  }
}

.mapfloorplan_container {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 10px;
  width: 600px;
  height: auto;
  background: rgba(0, 0, 0, 0.01);
  border: 0px solid rgb(0, 0, 0);
  border-radius: 6px;
  backdrop-filter: blur(5px);
  transform: translateX(5px) scale(0);
  opacity: 1;
  transform-origin: top right;
  transition: all 0.3s;

  &.open {
    display: block;
    transform: translateX(5px) scale(1);

    &-aacorp {
      transform: translate(0, -640px) scale(1);
      width: 800px;
    }

    @media (max-width: 1023px) {
      transform-origin: center;
      transform: translate(-50%, -50%) scale(1.5);
    }
    @media (max-width: 767px) {
      transform: translate(-50%, -50%) scale(0.9);
    }
  }

  .mapfloorplan_close {
    position: absolute;
    top: -10px;
    left: -10px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    background: none;
    color: #333;
    &-btn {
      font-family: EB Garamond, sans-serif;
      display: flex;
      height: 40px;
      padding: 0px 10px;
      line-height: 1.1;
      border-radius: 50%;
      font-size: 30px;
      border: 1px solid gray;
      cursor: pointer;
      opacity: 0.85;
      &:hover {
        opacity: 1;
      }
    }
  }

  @media (max-width: 1023px) {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
  }

  @media (min-width: 1441px) {
    right: 15px;

    &-aacorp {
      right: 10px;
    }
  }

  .map_header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-bottom: 5px;

    .map_dropdown {
      height: 40px;
      width: 140px;
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, 0.6);
      border: 0px solid rgb(0, 0, 0);
      border-radius: 6px;
      justify-content: flex-start;
      transition: opacity 0s ease 0s, background-color 500ms ease 0ms;
      color: white;
      position: relative;
      cursor: pointer;

      span {
        margin-left: 15px;
      }

      .map_arrow {
        border: solid white;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 2px;
        position: absolute;
        right: 8px;
      }

      .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }

      .map_dropdown_options {
        z-index: 9;
        position: absolute;
        height: auto;
        width: 100%;
        top: 105%;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 6px;
        transform-origin: top;
        transition: all 0.5s;

        .dropdown_items {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;

          li {
            height: 40px;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            border-radius: 6px;
            padding: 0 5px;
            transition: opacity 0s ease 0s, background-color 500ms ease 0ms;
            padding: 0 15px;

            &:hover {
              background: rgba(255, 255, 255, 0.5);
            }
          }
        }
      }
    }
  }

  .map_image {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    .scene-title {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -150%);
      // bottom: 20px;
      padding: 5px;
      border-radius: 5px;
      font-size: 12px;
      white-space: nowrap;
      color: #fff;
      background-color: #000;
      opacity: 0;
      pointer-events: none;
      z-index: 3;
    }

    .scene-title::after {
      content: '';
      position: absolute;
      top: calc(100% - 3px);
      left: 50%;
      transform: translateX(-50%);
      border: 8px solid transparent;
      border-top-color: #000;
    }

    .viewpoint-container {
      position: absolute;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      cursor: pointer;
      z-index: 1;
      pointer-events: all;

      &:hover {
        .scene-title {
          opacity: 1;
        }

        .viewpoint {
          animation: none;
        }
      }
    }

    .viewpoint-container:hover + .scene-title {
      opacity: 1;
    }

    .circular-sector {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      pointer-events: none;
    }

    .current-viewpoint {
      background: #f73d3c;
      box-shadow: 0 0 0 4px var(--tour-brandColor);
      animation: blink 1s linear infinite;
      height: 10px;
      width: 10px;
      z-index: 1;
    }

    .viewpoint {
      height: 14px;
      width: 14px;
      border: 2px solid white;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
      background: rgb(2, 90, 2);
      animation: blink-opacity 1s linear infinite;

      &:hover {
        animation: none;
        z-index: 1;
      }
    }

    @keyframes blink {
      0% {
        box-shadow: 0 0 0 4px #f73d3c;
      }
      40% {
        box-shadow: 0 0 0 4px #fff;
      }
      100% {
        box-shadow: 0 0 0 4px #f73d3c;
      }
    }

    @keyframes blink-opacity {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &-school_layout {
    @media (min-width: 480px) {
      position: absolute;
      top: 0;
      right: 50px;
      left: unset;
    }
  }
}
