.Hotspot-popup {
  position: fixed;
  z-index: 90;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(0, 0, 0, 0.7)),
      color-stop(70%, transparent),
      to(transparent)
    );
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7),
      transparent 70%,
      transparent
    );
  }

  &-header {
    position: absolute;
    top: 1%;
    width: 100%;
    // padding: 0.5rem;
    z-index: 2;

    .Close-button {
      display: block;
      position: absolute;
      width: fit-content;
      height: fit-content;

      @media screen and (min-width: 768px) {
        left: 50%;
        transform: translateX(-50%);
      }

      @media (min-width: 576px) and (max-width: 767px) {
        right: 40px;
        left: auto;
        transform: none;
      }

      @media screen and (max-width: 575px) {
        right: 20px;
        left: auto;
        transform: none;
      }
    }
  }
  &-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
    display: flex;

    &.Menu-left {
      padding-left: 40px;
    }

    &.Menu-bottom {
      padding-bottom: 80px;
      @media (max-width: 600px) {
        padding-bottom: 0;
      }
    }

    .Popup-body-content {
      position: relative;
      width: 100%;
      flex: 1;
    }
  }
}
