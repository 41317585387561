.position-absolute {
  position: absolute;
}

.container-2d-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  margin: 0;

  @media (min-width: 768px) {
    top: 50px;
    margin: 0 auto;
    width: 80vw;
    height: auto;
  }

  .map-2d__list {
    list-style-type: none;
    height: 50px;
    width: fit-content;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    overflow: visible;
    overflow-x: scroll;
    z-index: 10;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 767px) {
      width: 100%;
      height: 100px;
      align-items: flex-end;
      bottom: 20px;
    }

    > li {
      position: relative;
      > button {
        cursor: pointer;
        width: 199px;
        height: 36px;
        padding: 0 20px;
        color: #0f0f0f;
        background-color: #f2f2f2;
        box-shadow: 0px 0px 5px #888888bf;
        text-align: center;
        border: none;
        outline: none;
        border-radius: 20px;

        @media (max-width: 767px) {
          width: 150px;
          height: 40px;
          padding: 0 10px;
          background-color: #f2f2f2c2;
          box-shadow: none;
          border-radius: 20px;
        }

        > span {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 767px) {
            font-size: 1rem;
            font-weight: 300;
            pointer-events: none;
          }
        }
      }

      > p {
        display: none;
        @media (max-width: 767px) {
          display: block;
          opacity: 0;
          visibility: hidden;
          transition: all 0.7s ease;
          position: absolute;
          top: -100%;
          left: 50%;
          transform: translate(-50%, -20px);
          width: max-content;
          max-width: 250px;
          text-align: center;
          background-color: #fff;
          font-size: 1.2rem;
          border: 1px solid #000015;
          border-radius: 20px;
          padding: 10px;

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            border-width: 8px 16px 8px 16px;
            border-style: solid;
            border-color: #000015 transparent transparent transparent;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 1px;
            left: 50%;
            transform: translate(-50%, 100%);
            border-width: 8px 16px 8px 16px;
            border-style: solid;
            border-color: #fff transparent transparent transparent;
          }
        }
      }

      &:last-child > p {
        @media (max-width: 767px) {
          transform: translate(-60%, -20px);
        }
      }

      &:first-child > p {
        @media (max-width: 767px) {
          transform: translate(-40%, -20px);
        }
      }

      &.active > button {
        color: #fff;
        background-color: #181c1e;
        border-color: #121517;

        @media (max-width: 767px) {
          background-color: #181c1ec2;
        }
      }

      &.active.show-detail > p {
        @media (max-width: 767px) {
          opacity: 1;
          visibility: visible;
          transition: all 0.7s ease;
        }
      }
    }
  }

  .badge-scene {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tour-brandColor);
    opacity: 0.8;
    color: white;
    font-size: 15px;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.7);
    transform: translate(-50%, -50%);
    cursor: pointer;

    .scene-title {
      position: absolute;
      bottom: 35px;
      padding: 5px;
      border-radius: 5px;
      font-size: 16px;
      white-space: nowrap;
      color: #fff;
      background-color: #000;
      opacity: 0;
    }

    .scene-title::after {
      content: '';
      position: absolute;
      top: calc(100% - 3px);
      left: 50%;
      transform: translateX(-50%);
      border: 8px solid transparent;
      border-top-color: #000;
    }

    &-text {
      display: inline-block;
      width: 100%;
      text-align: center;
      line-height: 60px;
    }

    &:hover {
      opacity: 1;
      z-index: 9999;

      .scene-title {
        opacity: 1;
      }
    }

    &-disabled {
      background: gray;

      &:hover {
        background: gray;
      }
    }
  }

  img {
    transition: all 2s ease-in;
    opacity: 0;
  }

  .viewpoint-container {
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    cursor: pointer;

    .scene-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 20px;
      padding: 5px;
      border-radius: 5px;
      font-size: 12px;
      white-space: nowrap;
      color: #fff;
      background-color: #000;
      opacity: 0;
    }

    .scene-title::after {
      content: '';
      position: absolute;
      top: calc(100% - 3px);
      left: 50%;
      transform: translateX(-50%);
      border: 8px solid transparent;
      border-top-color: #000;
    }

    &:hover {
      .scene-title {
        opacity: 1;
      }

      .viewpoint {
        animation: none;
      }
    }
  }

  .current-viewpoint {
    background: var(--tour-brandColor);
    box-shadow: 0 0 0 4px var(--tour-brandColor);
    animation: blink 1s linear infinite;
    height: 10px;
    width: 10px;
  }

  .viewpoint {
    height: 14px;
    width: 14px;
    border: 2px solid white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    background: rgb(2, 90, 2);
    animation: blink-opacity 1s linear infinite;

    &:hover {
      animation: none;
    }
  }

  @keyframes blink {
    0% {
      box-shadow: 0 0 0 4px var(--tour-brandColor);
    }
    40% {
      box-shadow: 0 0 0 4px #fff;
    }
    100% {
      box-shadow: 0 0 0 4px var(--tour-brandColor);
    }
  }

  @keyframes blink-opacity {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}

.loading-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.react-transform-wrapper {
  width: 100vw;
  height: 100% !important;

  @media (min-width: 768px) {
    width: 100% !important;
    height: calc(100vh - 115px) !important;
  }

  .transform-2d-map {
    width: 100%;
    @media (max-width: 767px) {
      height: fit-content;
    }

    img {
      width: 100%;
      height: auto;
      @media (max-width: 767px) {
        height: 100%;
      }
    }

    .floor-image {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.map2d-btn-zoom {
  position: absolute;
  top: 1%;
  right: 10%;
  z-index: 3;
  align-items: center;
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }

  &--inout {
    display: flex;
    align-items: center;
    opacity: 0.8;
    justify-content: space-between;
    color: #000;
    background-color: #fff;
    width: 135px;
    height: 35px;
    padding: 5px 10px;
    border-radius: 30px;

    svg {
      opacity: 0.5;
      cursor: pointer;
      width: 20px;
      &:hover {
        opacity: 0.85;
      }
      &:active {
        opacity: 1;
      }
    }
  }
}
