.experience-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.7);
  font-family: var(--secondary-font);
  font-weight: 200;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  // background-image: url(https://goducthanh.com/vnt_upload/File/05_2023/0A8EBCA8-627A-4DF4-B957-9889BE670519_1.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  .welcome_text {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    z-index: 99;
    color: #e7e8ec;
    font-size: 50px;
    font-weight: bold;
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.9);

    @media (max-width: 1023px) {
      top: 35%;
    }

    @media (max-width: 767px) {
      top: 40%;
      font-size: 25px;
    }
  }

  .experience-slider {
    position: fixed;
    width: 100vw;
  }

  .experience-button {
    position: fixed;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 30px;
    border: none;
    border-radius: 30px;
    background-color: #17a35b;
    font-size: 35px;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
    white-space: nowrap;
    z-index: 100;

    &:hover {
      background-color: #058040;
    }

    @media (min-width: 577px) and (max-width: 1400px) {
      top: 85%;
      font-size: 35px;
    }

    @media (max-width: 991px) {
      top: 60%;
    }

    @media (max-width: 576px) {
      top: 56%;
      font-size: 20px;
    }
  }

  .experience-content {
    width: 100%;
    height: 100%;
    padding: 40px 40px 140px 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;

    .click-drag,
    .viewer-scene,
    .viewer-info,
    .info-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .language-select {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 577px) and (max-width: 1400px) {
      font-size: 18px;
    }

    @media (max-width: 576px) {
      width: 100vw;
      font-size: 16px;
    }

    .language-select__icon {
      margin-bottom: 10px;
      display: inline-flex;
      align-items: center;
    }
  }

  .click-drag,
  .viewer-scene,
  .viewer-info,
  .info-button {
    user-select: none;

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 50vh;
    }
  }

  .viewer-scene {
    @media (max-width: 576px) {
      .explication {
        margin-top: 10px;
      }
    }
  }

  .click-drag__icon,
  .foot-step__icon {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .viewer-info__icon {
    img {
      width: 143px;
      height: 160px;
    }
  }

  .explication {
    color: #fff;
    font-size: 24px;
    margin-left: 20px;

    @media (min-width: 577px) and (max-width: 1400px) {
      font-size: 18px;
    }

    @media (max-width: 576px) {
      font-size: 16px;
      margin-left: 0;
    }
  }

  .row-button {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .icon {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      background: black;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    @media (max-width: 576px) {
      .explication {
        margin-left: 10px;
      }
    }
  }

  .list-button .row-button:last-child {
    margin-bottom: 0;
  }

  .left-button,
  .right-button {
    position: absolute;
    top: 50%;
    border: solid #fff;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 10px;
    background: transparent;
    z-index: 10;
  }

  .left-button {
    left: 20px;
    transform: translateY(-50%) rotate(135deg);
  }

  .right-button {
    right: 20px;
    transform: translateY(-50%) rotate(-45deg);
  }
}
