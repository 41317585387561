@import '../../scss/mixin.scss';
.powered-container {
  display: none;
  position: fixed;
  transition: all 1.5s ease-in-out;
  width: 100px;

  @media (min-width: 481px) {
    display: block;
    width: calc(100vw - 20px);
    left: 50%;
    transform: translate(-50%, 0);
  }

  @media (min-width: 1441px) {
    width: calc(100vw - 30px);
  }

  &.intro-in {
    bottom: -50px;
    opacity: 0;
  }

  &.intro-out {
    bottom: 10px;
    opacity: 1;

    @media (min-width: 1441px) {
      bottom: 15px;
    }
  }

  div {
    width: 150px;
    display: flex;
    a,
    img {
      display: block;
      width: 70px;
      margin: 0;
      opacity: 0.5;

      &:hover {
        opacity: 0.9;
      }
    }
    a {
      padding: 0;
    }
  }

  &_school-layout {
    width: unset;
    bottom: 0 !important;
    right: 0;
    margin: 10px;
    left: unset;
    transform: unset;
    .wrapper {
      display: block;
      p {
        font-size: 16px;
        color: white;
        text-shadow: 1px 1px gray;
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }
    }

    @media (min-width: 1441px) {
      margin: 15px;
    }
  }

  &_aacorp {
    display: block;
  }

  &__aacorp-img {
    // margin-left: 10px;
    height: 35px;
    width: 35px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-5px, 5px);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.85);
      img {
        opacity: 1;
      }
    }

    @media (max-width: 1441px) {
      transform: unset;
    }

    @media (max-width: 1023px) {
      height: 32px;
      width: 32px !important;
      background-color: rgba(0, 0, 0, 1);
      img {
        opacity: 1;
      }
    }

    @media (max-width: 480px) {
      margin-left: 10px;
      margin-bottom: 5px;
    }
  }

  &__aacorp-home-icon {
    cursor: pointer;
    height: 20px;
    width: 25px !important;

    @media (max-width: 1023px) {
      opacity: 1 !important;
    }
  }
}
