$primary-color-passcode: black;
$secondary-color-passcode: white;

.popup-passcode {
  position: fixed;
  width: 360px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background-color: white;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border: 1px solid #4f4f4f;

  @media (max-width: 360px) {
    width: calc(100vw - 20px);
  }
}

.popup-passcode-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-passcode-title {
  display: block;
  width: fit-content;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  color: black;
}

.popup-passcode-description {
  font-size: 18px;
  font-weight: 500;
  color: black;
}

.form-passcode {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-passcode {
  width: 100%;
  height: 40px;
  font-size: 18px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid #666;
  border-radius: 5px;
}

.error-message {
  width: fit-content;
  height: 24px;
  margin: 8px 0;
  font-size: 18px;
  color: red;
}

.access-popup-pc {
  height: 40px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: $primary-color-passcode;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.close-popup-pc {
  position: absolute;
  top: 2px;
  right: 2px;
  display: block;
  border: none;
  background-color: transparent;
  padding: 0;

  .cls-icon {
    width: 30px;
    height: 30px;
  }
}

.access-popup-pc.disabled {
  color: #d1d1d1;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: not-allowed;
}
