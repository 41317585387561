@import '../../../scss/variables.scss';

.Popup-content-matterport {
  .Popup-content-wp {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .html-embeded-content {
      position: absolute;
      top: -27px;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
    }

    iframe {
      flex: 1;
    }

    @media screen and (min-width: $size-min-md) {
      top: 10vh;
      bottom: 90vh;
      left: 0;
      right: 0;

      .html-embeded-content {
        padding: 0 10vw;
      }

      iframe {
        width: 80vw;
        height: 87vh;
      }
    }
  }
}
