$hsWidth: 30px;
$hsMaxWidth: 34px;
$mWidth: 200px;

.media-hotspot {
  width: $hsWidth;
  height: $hsWidth;
  position: relative;
  cursor: pointer;

  &_hide-cursor {
    cursor: none;
  }

  .m-info {
    display: none;
    position: absolute;
    min-width: 250px;
    top: -8px;
    left: -2px;
    padding: 5px;
    height: 36px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    font-family: var(--hotspot-font);

    p {
      margin: 0;
      color: #6c6a6a;
      margin-top: 0;
      padding-left: 40px;
      margin-bottom: 5px;
      font-size: 0.8rem;
    }

    .m-title {
      p {
        white-space: nowrap;
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: 600;
        color: black;
        line-height: 1rem;
      }
    }
    .m-type {
      p {
        font-size: 0.65rem;
        white-space: nowrap;
        line-height: 0.65rem;
      }
    }
  }

  &-air__balloon {
    transform: translateY(-27px);
    .hotspot_icon {
      border-width: 2px !important;
    }
    img {
      box-shadow: none !important;
    }
  }

  .m-air-balloon__info {
    left: 50%;
    top: -45px;
    min-width: 100px;
    transform: translateX(-50%);
    p {
      width: auto;
      padding-left: 30px;
      padding-right: 30px;
      text-align: center;
    }
  }

  .m-icon {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    svg {
      position: absolute;
      z-index: 2;
    }

    &-air__balloon-square {
      z-index: -1;
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: white;
      transform: rotate(45deg);
      top: 18px;
      left: 25%;
    }
  }

  &.hovering {
    animation: none;
    -webkit-animation: none;
    -moz-animation: none;

    .m-info {
      display: block;
    }
  }

  > .s-visited {
    position: absolute;
    width: $hsWidth * 0.6;
    height: $hsWidth * 0.6;
    bottom: 60%;
    left: 60%;
    z-index: 5;
  }
}

@-webkit-keyframes hpScaleBig {
  0% {
    width: $hsWidth;
    height: $hsWidth;
  }
  50% {
    width: $hsMaxWidth;
    height: $hsMaxWidth;
  }
  100% {
    width: $hsWidth;
    height: $hsWidth;
  }
}

@keyframes hpScaleBig {
  0% {
    width: $hsWidth;
    height: $hsWidth;
  }
  50% {
    width: $hsMaxWidth;
    height: $hsMaxWidth;
  }
  100% {
    width: $hsWidth;
    height: $hsWidth;
  }
}
